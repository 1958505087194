import { useState, useRef } from "react";
import PropTypes from "prop-types";

export default function CreditCardInput({ setCardNumber }) {
  const [inputs, setInputs] = useState(['', '', '', '']);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleInput = (index, value, event) => {
    const newInputs = [...inputs];

    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    if (event.key === 'Backspace') {
      if (value === '' && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else {
      newInputs[index] = sanitizedValue.slice(0, 4);
      setInputs(newInputs);

      if (sanitizedValue.length === 4 && index < 1) {
        inputRefs[index + 1].current.focus();
      }
    }

    if (index === 1) {
      newInputs[2] = sanitizedValue.length === 4 ? 'XXXX' : '';
      newInputs[3] = sanitizedValue.length === 4 ? 'XXXX' : '';
    }
    setCardNumber(newInputs.slice(0, 2).join(''));
  };

  const renderInput = (id, index, disabled = false) => (
    <input
      className="w-1/2 appearance-none font-Inter rounded-[10px] md:h-[60px] border border-[#E5E5E5] focus:outline-none md:py-3 text-center bg-[#F7F7F7] placeholder-shown:font-normal placeholder-[#20244E]"
      id={`input${id}`}
      value={inputs[index]}
      inputMode="numeric"
      onChange={(e) => handleInput(index, e.target.value.replace(/[^0-9]/g, ''), e)}
      onKeyDown={(e) => handleInput(index, e.target.value, e)}
      maxLength={4}
      disabled={disabled}
      ref={inputRefs[index]}
    />
  );

  return (
    <div className="flex creditCardInput justify-between text-center gap-1 md:gap-4 text-[#464646] md:text-xl font-normal">
      {renderInput(1, 0)}
      {renderInput(2, 1)}
      {renderInput(3, 2, true)}
      {renderInput(4, 3, true)}
    </div>
  );
}

CreditCardInput.propTypes = {
  setCardNumber: PropTypes.func.isRequired,
};
import { IoCloseCircle } from 'react-icons/io5';

import { check } from "../assets/svg/index.js";

export const SuccessMessage = ({ message }) => {
	return (
		<>
			{message && (
				<div className="flex mt-2 gap-2 text-sm text-[#09CB11] font-Inter font-medium">
					<img src={check} alt="check" />
					{message}
				</div>
			)}
		</>
	)
}

export const ErrorMesage = ({ error, message }) => {
	return (
		<>
			{error && !message && (
				<div className="flex items-center mt-2 gap-2 text-sm text-red-600 font-Inter font-medium">
					<IoCloseCircle color="#e52424" size={18} />
					{error}
				</div>
			)}
		</>
	)
}

export const getSubdomainInfo = () => {
	const hostname = window.location.hostname;
	const isLocalhost = hostname.includes("localhost");

	let subdomainName = null;
	let isSubdomain = false;

	// Check for subdomain in development environment
	if (isLocalhost) {
		const parts = hostname.split(".");
		// Assuming pattern subdomain.localhost
		if (parts.length > 1 && parts[0] !== "localhost") {
			subdomainName = parts[0];
			isSubdomain = true;
		}
	} else {
		// Check for subdomain in production environment
		const parts = hostname.split(".");
		// Check if there are more than two parts, indicating a subdomain is present
		if (parts.length > 2) {
			subdomainName = parts[0];
			isSubdomain = true;
		}
		// Check for specific subdomain and main domain
		if (subdomainName === "www") {
			isSubdomain = false; // It's the main domain
			subdomainName = null; // Clear subdomain name
		} else if (subdomainName && subdomainName !== "www") {
			isSubdomain = true; // Any other keyword is a valid subdomain
		}
	}

	return { isSubdomain, subdomainName };
};

export const formatAmount = (amount) => {
	return new Intl.NumberFormat('en-IN', {
		style: 'decimal',
		maximumFractionDigits: 2
	}).format(amount);

};

// Function to format numbers to Indian currency format
export const formatIndianNumber = (num) => {
	return new Intl.NumberFormat('en-IN', {
		maximumFractionDigits: 2,
		style: 'decimal'
	}).format(num);
};

export const formatDiscountRoundOff = (discount) => {
	const rounded = Number(discount).toFixed(2);
	return rounded.endsWith('.00') ? parseInt(rounded) : parseFloat(rounded);
};

// Date Format Generate

// Array of month names
export const monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];

// Function to add ordinal suffix to day
export function getOrdinalSuffix(day) {
	if (day > 3 && day < 21) return 'th';
	switch (day % 10) {
		case 1: return "st";
		case 2: return "nd";
		case 3: return "rd";
		default: return "th";
	}
}

export const colorDetect = (status) => {
  if (status === "REFUNDED") {
    return {
      text: "Rejected",
      color: "#e53030",
    }
  } else if (status === "PENDING") {
    return {
      text: "Pending",
      color: "#ffbe41",
    }
  } else if (status === "COMPLETED") {
    return {
      text: "Completed",
      color: "#6dc965",
    }
  } else {
    return {
      text: "Not Available",
      color: "#D9D9D9",
    }
  }
}
import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { HiMiniXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Dropdown from "../Dropdown";
import SearchBar from "../Searchbar";

import { bankco, menuIcon, profileImage, rewardIconSvg, search, support, recentSearch } from "../../../assets/svg";

import { useAuth } from "../../../hoc/AuthContext";
import useWindowSize from "../../../hooks/useWindowSize";
import { userDetail } from "../../../Services/APIs/RewardListing";
import { getOfferTypeResult } from "../../../Utils/Constant";

import Login from "../../LoginSignup";
import SignUp from "../../LoginSignup/SignUp";

import Spinner from "../Spinner";

export default function Header() {
  const [show, setShow] = useState(false);
  const [showSearchbarMoblie, setShowSearchBarMobile] = useState(false);
  const [showMenuMoblie, setShowMenuMobile] = useState(false);
  const [searchTerm, SetSearchTerm] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [user, setUser] = useState({});
  const [userCardInfo, setUserCardInfo] = useState({});
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [cards, setCards] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchresult, setSearchResult] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [recentSearchesState, setRecentSearchesState] = useState(JSON.parse(localStorage.getItem('recentSearches')) || []);

  const navigate = useNavigate();
  const { isAuthenticated, logout, setCardInfo, selectedCardData, setSelectedCardData, setUserData, showLogin, setShowLogin, setIsCardChange, setSubVarientIdGlobal, subVarientIdGlobal } = useAuth();
  const isMobile = useWindowSize();
  const drawerLoginRef = useRef(null);
  const drawerSignUpRef = useRef(null);
  const resultsRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShow(false);
        // SetSearchTerm('');
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showLogin) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLogin]);

  useEffect(() => {
    if (showSignUp) {
      document.addEventListener("mousedown", handleSignUpClickOutside);
    } else {
      document.removeEventListener("mousedown", handleSignUpClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleSignUpClickOutside);
    };
  }, [showSignUp]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropDown);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  const handleShowLogin = () => {
    setShowLogin(!showLogin);
    setShowSignUp(false);
    setShowSearchBarMobile(false);
    setShowMenuMobile(false);
    setShow(false);
    SetSearchTerm('');
  };

  const handleShowSignUp = () => {
    setShowSearchBarMobile(false);
    setShowLogin(false);
    setShowSignUp(!showSignUp);
    setShowMenuMobile(false);
    setShow(false);
    SetSearchTerm('');
  };

  const handleClickOutside = (event) => {
    if (drawerLoginRef.current && !drawerLoginRef.current.contains(event.target)) {
      setShowLogin(false);
      setCardInfo(null);
      setShow(false);
      SetSearchTerm('');
    }
  };

  const handleSignUpClickOutside = (event) => {
    if (drawerSignUpRef.current && !drawerSignUpRef.current.contains(event.target)) {
      setShowSignUp(false);
      setCardInfo(null);
      setShow(false);
      SetSearchTerm('');
    }
  };

  const fetchUserData = async () => {
    try {
      const result = await userDetail();

      if (result.status === 200) {
        setUser(result?.data?.user);
        const filterCardInfo = result?.data?.cards?.filter((item) => item?.is_default);
        const selectedCard = selectedCardData?.sub_variant?.id
        localStorage.setItem("subId", selectedCard ? selectedCard : filterCardInfo?.[0]?.sub_variant?.id);
        setUserCardInfo(filterCardInfo?.[0]);
        setUserData(result?.data?.user);
        setCards(result?.data?.cards);
      }
    } catch (e) {
      if (e.status === 403) {
        logout();
        navigate("/");
      }
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    setIsOpenDrawer(false);
    setShowMenuMobile(false);
    setShow(false);
    SetSearchTerm('');
  };

  // selected card change function 
  const handleCardClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCardSelect = (card) => {
    setSelectedCardData(card);
    setIsCardChange(true);
    setSubVarientIdGlobal(card?.sub_variant?.id);
    localStorage.setItem("selectedCard", JSON.stringify(card));
    localStorage.setItem("subId", JSON.stringify(card?.sub_variant?.id));
    setShowDropdown(false);
  };

  const handleClickOutsideDropDown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  const cardDataToShow = Object.keys(selectedCardData).length === 0 ? userCardInfo : selectedCardData;

  const handleNavigation = (path) => {
    setIsOpenDrawer(false);
    navigate(path);
    if (isMobile) setShowMenuMobile(false);
  };

  function renderDropDownHeader() {
    return (
      <div className="py-0 profile_dropdown">
        <span className="cursor-pointer">
          <img src={profileImage} alt={user?.name} />
        </span>
      </div>
    );
  }

  function renderDropDownBody() {
    return (
      <>
        <div className={`bg-[#F3F3F3] flex gap-4 items-start truncate text-lg font-Inter border-[#BDBDBD] border rounded-t-[20px] ${isMobile ? "w-full py-4 container mx-auto px-5" : "w-[308px] p-4"} `}>
          <img src={profileImage} alt={user?.name} />
          <div className="flex flex-col text-wrap">
            Hello {user?.name},
            <div className={`${isMobile ? "text-[#00F]" : "text-[#F5437E]"}  font-semibold`}>
              {user?.mobile_number}
            </div>
          </div>
        </div>

        <div className={`flex flex-col gap-4 md:gap-6 ${isMobile ? "py-6 container mx-auto px-5" : "p-6"} font-Inter font-semibold text-[16px]`}>
          <div className="md:hidden flex items-start rounded-[10px] gap-2 relative cursor-pointer" onClick={handleCardClick} ref={dropdownRef}>
            <img src={cardDataToShow?.sub_variant?.payment_network?.logo} alt={cardDataToShow?.sub_variant?.payment_network?.name}
              className="w-6 h-6" loading="lazy" />

            <div className="font-medium flex items-center justify-between w-full">
              {cardDataToShow?.sub_variant?.bank?.display_name !== "*" && cardDataToShow?.sub_variant?.bank?.display_name + " "}
              {cardDataToShow?.sub_variant?.name !== "*" && cardDataToShow?.sub_variant?.name + " "}
              {cardDataToShow?.sub_variant?.name === "*" && cardDataToShow?.sub_variant?.payment_network?.name}  {" "}
              {cardDataToShow?.sub_variant?.name === "*" && cardDataToShow?.sub_variant?.variant?.name}  {" "}
            </div>
            {showDropdown ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
            {showDropdown && (
              <div className="absolute h-[200px] top-full left-0 mt-2 bg-white z-10 w-full">
                {cards.map((card) => (
                  <div key={card.id} className="p-2 text-sm cursor-pointer hover:bg-gray-200" onClick={() => handleCardSelect(card)}>
                    {card?.sub_variant?.bank?.display_name !== "*" && card?.sub_variant?.bank?.display_name + " "}
                    {card?.sub_variant?.name !== "*" && card?.sub_variant?.name + " "}
                    {card?.sub_variant?.name === "*" && card?.sub_variant?.payment_network?.name}  {" "}
                    {card?.sub_variant?.name === "*" && card?.sub_variant?.variant?.name}  {" "}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="cursor-pointer" onClick={() => handleNavigation('/userProfile/assignedOffer')}>
            Assigned Offers
          </div>
          <div className="cursor-pointer" onClick={() => handleNavigation('/userProfile')}>
            Profile
          </div>
          <div className="cursor-pointer" onClick={() => handleNavigation('/userProfile/order')}>
            Orders
          </div>

          <div className="flex cursor-pointer justify-between w-full pr-4" onClick={() => handleNavigation('/userProfile/reward')}>
            <div>Loyality Points</div>

            <div className="flex gap-1">
              <img src={rewardIconSvg} alt="rewardIconSvg" />
              <div>{user?.points}</div>
            </div>
          </div>

          <div onClick={() => handleLogout()} className="cursor-pointer hover:rounded-b-[20px]">
            Logout
          </div>
        </div>
      </>
    );
  }

  function renderAunthenticatedWebView() {
    return (
      <div className="md:flex gap-3.5 hidden">
        {isAuthenticated ? (
          <Dropdown
            dropDownHeader={renderDropDownHeader}
            dropdownBody={renderDropDownBody}
            isOpenDrawer={isOpenDrawer}
            setIsOpenDrawer={setIsOpenDrawer}
            isShowIcon={false}
          />
        ) : (
          <>
            <button
              className="border font-Inter text-[16px] font-medium border-[#1e1e1e] rounded-[10px] p-0 h-10 w-[83px]"
              onClick={() => handleShowLogin()}
            >
              Sign In
            </button>

            <button
              className="font-Inter bg-[#F5437E] text-white border border-[#ED1C24] rounded-[10px] p-0 h-10 w-[83px] text-[16px] font-medium"
              onClick={() => handleShowSignUp()}
            >
              Sign Up
            </button>
          </>
        )}
      </div>
    );
  }

  function renderMobileViewHeaderOption() {
    return (
      <div className="flex md:hidden gap-4">
        <div
          className="bg-[#F7F7F7] flex justify-center items-center w-12 h-12 p-3 rounded-md cursor-pointer"
          onClick={() => {
            setShowMenuMobile(false);
            setShowSearchBarMobile(!showSearchbarMoblie);
            setShow(false);
            SetSearchTerm('');
          }}
        >
          {showSearchbarMoblie
            ? <HiMiniXMark color="#3B3E63" size={26} />
            : <img src={search} alt="search" loading="lazy" />
          }
        </div>

        <div
          className="bg-[#F5437E] w-12 h-12 p-3 rounded-md cursor-pointer"
          onClick={() => {
            setShowMenuMobile(!showMenuMoblie);
            setShowSearchBarMobile(false);
          }}
        >
          {showMenuMoblie
            ? <HiMiniXMark color="#fff" size={26} />
            : <img src={menuIcon} alt="search" loading="lazy" />
          }
        </div>
      </div>
    );
  }

  function renderMobileSearchBarView() {
    return (
      <>
        {showSearchbarMoblie && (
          <div className={`container mt-2 px-5 relative z-0 mx-auto ${showSearchbarMoblie && isMobile ? "flex" : "hidden"}`}>
            <SearchBar
              setSearchResult={setSearchResult}
              searchTerm={searchTerm}
              SetSearchTerm={SetSearchTerm}
              setSearchLoading={setSearchLoading}
              setShow={setShow}
            />
          </div>
        )}
      </>
    );
  }

  function renderMobileMenubarView() {
    return (
      showMenuMoblie && (
        <div className={`relative font-Inter mt-2.5 z-0 shadow-lg rounded-[20px] bg-white w-full mx-auto ${showMenuMoblie && isMobile ? "flex flex-col" : "hidden"}`}>
          {isAuthenticated ? renderDropDownBody() : (
            <div className="container mx-auto px-16 py-4 flex flex-col gap-4">
              <div className="font-medium text-lg cursor-pointer">About Us</div>
              <div className="font-medium text-lg cursor-pointer">Services</div>
              <div className="font-medium text-lg cursor-pointer">Partners</div>
              <div className="font-medium text-lg cursor-pointer">Benefits</div>
              <button
                className="border font-Inter text-center w-44 text-xl font-medium border-[#1e1e1e] rounded-[10px] h-14"
                onClick={() => handleShowLogin()}
              >
                Sign In
              </button>
            </div>
          )}
        </div>
      )
    );
  }

  const saveRecentSearch = (searchTerm) => {
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];

    // Remove the searchTerm if it already exists to avoid duplicates
    const filteredSearches = recentSearches.filter(term => term !== searchTerm);

    // Add the new search term to the beginning of the array
    filteredSearches.unshift(searchTerm);

    // Store only the last 5 searches
    const updatedSearches = filteredSearches.slice(0, 5);

    // Save back to localStorage
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));

    // Update state
    setRecentSearchesState(updatedSearches);
  };

  function renderSearchResult() {
    const searchresultData = searchresult?.length > 0 ? searchresult : "No exact matches found";

    const handleOfferTypeClick = (type, id, name) => {
      setShow(false);
      SetSearchTerm('');

      // Save the recent search to localStorage
      saveRecentSearch(name);

      if (type === 'recent_search') {
        SetSearchTerm(name);
        setShow(true);
        // You can trigger a new search or navigate to the relevant page
        return;
      }

      // Check if the user is authenticated
      if (!isAuthenticated) {
        setShowLogin(true);
        setShow(false);
        SetSearchTerm('');
        return;
      }

      let path = '/rewards';
      const queryParams = new URLSearchParams();

      queryParams.set('card_id', subVarientIdGlobal);

      switch (type) {
        case 'offer_category':
          queryParams.set('offer_types', JSON.stringify(["merchant_funded_offers", "affiliate_cashback", "access_n_membership", "gift_card"]));
          queryParams.set('filters', JSON.stringify({ offer_categories: [id] }));
          break;
        case 'brand_category':
          queryParams.set('offer_types', JSON.stringify(["merchant_funded_offers", "affiliate_cashback", "access_n_membership", "gift_card"]));
          queryParams.set('filters', JSON.stringify({ brand_categories: [id] }));
          break;
        case 'offer_type':
          queryParams.set('offer_types', JSON.stringify([id]));
          break;
        case 'brand':
          queryParams.set('offer_types', JSON.stringify(["merchant_funded_offers", "affiliate_cashback", "access_n_membership", "gift_card"]));
          queryParams.set('filters', JSON.stringify({ brands: [id] }));
          break;
        default:
          return;
      }

      navigate(`${path}?${queryParams.toString()}`, {
        state: {
          fromSearch: true,
          isCardUrl: true,
          cardURL: type === "offer_type" ? id : "all_rewards"
        }
      });
    };

    const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];

    // Function to remove a specific search term from localStorage and update state
    const removeSearchFromLocalStorage = (searchToRemove) => {
      const updatedSearches = recentSearches.filter(search => search !== searchToRemove);

      localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
      setRecentSearchesState(updatedSearches);
    };

    // Helper function to display the correct label for offer types
    const getOfferLabel = (search) => {
      switch (search) {
        case 'merchant_funded_offers':
          return 'Discount Offers';
        case 'affiliate_cashback':
          return 'Cashback';
        case 'gift_card':
          return 'Gift Cards';
        case 'access_n_membership':
          return 'Access & Membership';
        default:
          return search;
      }
    };

    return (
      show && (
        <div className={`relative container mx-auto px-5 ${isMobile && "pt-4"} md:pl-[214px]`}>
          <div
            className="absolute w-full flex-col searchBar border z-20 flex gap-4 items-center bg-[#F7F7F7] border-[#E5E5E5] rounded-[8px] md:w-[280px] lg:w-[420px] p-4"
            ref={resultsRef}
          >
            {searchLoading ? <div className="flex justify-center items-center w-full"><Spinner /></div> : (
              <>
                {Array.isArray(searchresultData) && searchresultData.length > 0 ? (
                  <div className="flex flex-col w-full gap-4">
                    {searchresultData.map((result, index) => {
                      // Extract the icon component
                      const OfferType = getOfferTypeResult(result?.name)?.Icon;

                      return (
                        <div key={index}>
                          {result?.source === "brand" ? (
                            <div className="p-4 rounded-md flex justify-between items-center gap-2.5 hover:bg-[#cfcfcfe3] bg-[#ebebeb] cursor-pointer w-full"
                              onClick={() => handleOfferTypeClick('brand', result?.id, result?.name)}
                            >
                              <img
                                className="w-12 h-12 rounded-[4px] object-contain"
                                style={{ backgroundColor: `#${result?.hex_code}` }}
                                src={result?.logo}
                                alt={result?.name}
                              />
                              <div className="flex flex-col w-full">
                                <div className="text-base font-semibold">{result?.name}</div>
                                {result?.tag_line && (
                                  <div className="text-sm text-[#f5437e]">{result?.tag_line}</div>
                                )}
                              </div>
                            </div>
                          ) : result?.source === "offer_type" ? (
                            <div
                              className="p-4 rounded-md flex items-center gap-2.5 hover:bg-[#cfcfcfe3] bg-[#ebebeb] cursor-pointer w-full hover:fill-[#F5437E]"
                              onClick={() => handleOfferTypeClick('offer_type', result?.name, result?.name)}
                            >
                              {OfferType && <OfferType />}
                              <div className="text-base font-semibold">
                                {getOfferTypeResult(result?.name)?.title}
                              </div>
                            </div>
                          ) : result?.source === "offer_category" ? (
                            <div
                              className="p-4 rounded-md flex items-center gap-2.5 hover:bg-[#cfcfcfe3] bg-[#ebebeb] cursor-pointer w-full hover:fill-[#F5437E]"
                              onClick={() => handleOfferTypeClick('offer_category', result?.id, result?.name)}
                            >
                              <div className="text-base font-semibold">
                                Spend Type - {result?.name}
                              </div>
                            </div>
                          ) : result?.source === "brand_category" ? (
                            <div
                              className="p-4 rounded-md flex items-center gap-2.5 hover:bg-[#cfcfcfe3] bg-[#ebebeb] cursor-pointer w-full"
                              onClick={() => handleOfferTypeClick('brand_category', result?.id, result?.name)}
                            >
                              {result?.bg_image ? (
                                <img className={`w-12 h-12 object-contain bg-gray-300 rounded-full`}
                                  src={result?.bg_image} alt={result?.name} />
                              ) : <div className="w-12 h-12 bg-gray-300 rounded-full" />
                              }
                              <div className="text-base font-semibold">{result?.name} </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full text-left text-base">
                    {searchresultData}
                  </div>
                )}
              </>
            )}

            {recentSearchesState.length > 0 && (
              <div className="w-full">
                <h3 className="font-semibold">Recent Searches</h3>
                <ul className="flex flex-wrap gap-2 mt-2">
                  {recentSearchesState.map((search, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer items-center rounded-[20px] bg-[#ebebeb] hover:bg-[#cdcdcd] flex justify-between gap-1"
                    >
                      <div className="flex gap-2.5 items-center" onClick={() => handleOfferTypeClick('recent_search', null, getOfferLabel(search))}>
                        <img src={recentSearch} alt="recentSearch" className="w-4 h-4" />
                        {getOfferLabel(search)}
                      </div>
                      <HiMiniXMark className="mt-1" onClick={() => removeSearchFromLocalStorage(search)} />
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )
    )
  }

  function renderLoginComponent() {
    return (
      showLogin && (
        <div className="container mt-2 px-5 flex mx-auto justify-center md:justify-end">
          <div className={`absolute z-30 container mx-auto md:max-w-md`} ref={drawerLoginRef}>
            <Login setShowLogin={setShowLogin} setShowSignUp={setShowSignUp} />
          </div>
        </div>
      )
    );
  }

  function renderSignUpComponent() {
    return (
      showSignUp && (
        <div className="container mt-2 px-5 flex mx-auto justify-center md:justify-end">
          <div className={`absolute z-30 container mx-auto md:max-w-md`} ref={drawerSignUpRef}>
            <SignUp setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} />
          </div>
        </div>
      )
    );
  }

  function renderCreditCardDropDown() {
    return (
      <div ref={dropdownRef} className="md:flex items-center bg-[#F7F7F7] p-2.5 rounded-[10px] hidden gap-2 relative cursor-pointer" onClick={handleCardClick}>
        <img src={cardDataToShow?.sub_variant?.payment_network?.logo} alt={cardDataToShow?.sub_variant?.payment_network?.name}
          className="w-6 h-6" loading="lazy" />

        <div className="font-Inter text-[10px] lg:text-sm font-medium">
          {cardDataToShow?.sub_variant?.bank?.display_name !== "*" && cardDataToShow?.sub_variant?.bank?.display_name + " "}
          {cardDataToShow?.sub_variant?.name !== "*" && cardDataToShow?.sub_variant?.name + " "}
          {cardDataToShow?.sub_variant?.name === "*" && cardDataToShow?.sub_variant?.payment_network?.name}  {" "}
          {cardDataToShow?.sub_variant?.name === "*" && cardDataToShow?.sub_variant?.variant?.name}  {" "}
        </div>

        {showDropdown ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
        {showDropdown && (
          <div className="absolute top-full left-0 mt-2 bg-white border rounded-md shadow-lg z-10 w-full">
            {cards.map((card) => (
              <div key={card.id} className="p-2 text-sm cursor-pointer hover:bg-gray-200" onClick={() => handleCardSelect(card)}>
                {card?.sub_variant?.bank?.display_name !== "*" && card?.sub_variant?.bank?.display_name + " "}
                {card?.sub_variant?.name !== "*" && card?.sub_variant?.name + " "}
                {card?.sub_variant?.name === "*" && card?.sub_variant?.payment_network?.name}  {" "}
                {card?.sub_variant?.name === "*" && card?.sub_variant?.variant?.name}  {" "}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="h-[72px] w-full pt-4 container px-5 mx-auto flex justify-between items-center">
        <div className="flex gap-10">
          <img src={bankco} alt="bankco" className="cursor-pointer" loading="lazy"
            onClick={() => isAuthenticated ? navigate('/rewards') : navigate('/')} />

          <div className="md:block relative hidden">
            <SearchBar
              setSearchResult={setSearchResult}
              searchTerm={searchTerm}
              SetSearchTerm={SetSearchTerm}
              setSearchLoading={setSearchLoading}
              setShow={setShow}
            />
          </div>
        </div>

        <div className="flex items-center gap-5">
          {isAuthenticated ? (
            renderCreditCardDropDown()
          ) : (
            <div className="md:flex hidden gap-2 cursor-pointer" onClick={() => navigate("contact-us")}>
              <img src={support} alt="support" className="mt-1 w-5 h-5" loading="lazy" />

              <div className="font-Inter text-[16px] font-medium">support</div>
            </div>
          )}

          {renderAunthenticatedWebView()}

          {renderMobileViewHeaderOption()}

        </div>
      </div>

      {renderMobileSearchBarView()}

      {renderMobileMenubarView()}

      {renderSearchResult()}

      {renderLoginComponent()}

      {renderSignUpComponent()}
    </div >
  );
};
import { ENDPOINTS } from "../constants.js";
import { GET, POST } from "../method.js";

export const sendOTP = async (mobileNumber, countryCode) => {
	const url = ENDPOINTS.OTP_VALIDATION;

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
	};

	return POST(url, data);
};

export const validateBinNumber = async (bin) => {
	const url = ENDPOINTS.VALIDATION_BIN(bin)
	return GET(url);
}

export const signUpAPI = async (name, bin, mobileNumber, countryCode, otp) => {
	const url = ENDPOINTS.SIGNUP;

	const data = {
		name: name,
		bin: bin,
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
		otp: otp,
	};

	return POST(url, data);
};

import { footerCustomer, footerInstitution, footerbrand, footersaved } from "../../assets/svg/index.js";

import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

export const FooterData = [
  {
    image: footerbrand,
    title: "1,000+ Brands Partnered",
  },
  {
    image: footersaved,
    title: "Rs 1,800 Cr Saved",
  },
  {
    image: footerInstitution,
    title: "25 + Institutions Served",
  },
  {
    image: footerCustomer,
    title: "~ 50 Mn Customers Served",
  },
];

export const FooterLogoData = [
  {
    logo: <FaFacebookF size={25} />,
    link: "https://www.facebook.com",
  },
  {
    logo: <FaTwitter size={25} />,
    link: "https://www.twitter.com",
  },
  {
    logo: <FaLinkedinIn size={25} />,
    link: "https://www.linkedin.com",
  },
  {
    logo: <FaYoutube size={25} />,
    link: "https://www.youtube.com",
  },
  {
    logo: <FaInstagram size={25} />,
    link: "https://www.instagram.com",
  },
];

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowSize from '../../../hooks/useWindowSize';

export function SkeletonLandingBannerSection() {
  return (
    <div className={`container relative px-5 mx-auto`}>
      <Skeleton className="relative my-9 md:my-6 z-0 w-full h-[150px] md:h-[350px] rounded-2xl md:rounded-[20px]" />
    </div>
  );
}

export function SkeletonWhatsNewCard() {
  return (
    <div className="container flex flex-col my-8 md:pt-0 pl-5 -pr-5 md:px-5 mx-auto relative gap-4">
      <div className="flex justify-between">
        <Skeleton width={200} height={36} />
        <div className="flex gap-4">
          <Skeleton circle width={40} height={40} />
          <Skeleton circle width={40} height={40} />
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-[280px] overflow-hidden">
        {[...Array(4)].map((_, index) => (
          <div key={index} className="relative py-5">
            <Skeleton width="100%" height={200} className="rounded-[20px]" />
            <div className="absolute w-full -bottom-8 lg:bottom-4 left-1/2 -translate-x-1/2">
              <Skeleton width="70%" height={100} className="rounded-[20px] left-1/2 -translate-x-1/2" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function SkeletonPopularCategory() {
  return (
    <div className="container flex flex-col my-8 md:pt-0 pl-5 -pr-5 md:px-5 mx-auto relative gap-4">
      <div className="flex justify-between">
        <Skeleton width={200} height={36} />
        <div className="flex gap-4">
          <Skeleton circle width={40} height={40} />
          <Skeleton circle width={40} height={40} />
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-5 h-[170px] overflow-hidden">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="flex flex-col gap-3.5 py-5">
            <Skeleton circle width={100} height={100} className="left-1/2 -translate-x-1/2" />
            <Skeleton height={20} />
          </div>
        ))}
      </div>
    </div>
  );
}

export function SkeletonPopularRetailer() {
  const isMobile = useWindowSize();
  return (
    <div className="container flex flex-col my-8 md:pt-0 pl-5 -pr-5 md:px-5 mx-auto relative gap-4">
      <div className="flex justify-between">
        <Skeleton width={200} height={36} />
        <div className="flex gap-4">
          <Skeleton circle width={40} height={40} />
          <Skeleton circle width={40} height={40} />
        </div>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-5 h-[75px] md:h-[120px] overflow-hidden">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="flex flex-col gap-3.5">
            <Skeleton width={isMobile ? 75 : 145} height={isMobile ? 50 : 100} />
          </div>
        ))}
      </div>
    </div>
  );
}

export function SkeletonPopularCategoryBanner() {
  const isMobile = useWindowSize();
  return (
    <div className="container flex flex-col my-8 md:pt-0 pl-5 -pr-5 md:px-5 mx-auto relative gap-4">
      {/* Mobile to medium-sized screens carousel skeleton */}
      <div className="md:px-5 relative h-[160px] md:h-[260px] overflow-hidden container mx-auto xl:mx-auto xl:hidden block">
        <div className="relative midBanner">
          <div className="flex gap-5">
            {[...Array(2)].map((_, index) => (
              <div key={index} className="flex-1">
                <Skeleton
                  width={isMobile ? 284 : 625}
                  height={isMobile ? 150 : 250}
                  className="rounded-2xl"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Large screens banner skeleton */}
      <div className="container mx-5 md:mx-auto hidden xl:flex items-center xl:justify-center gap-8">
        {[...Array(2)].map((_, index) => (
          <div key={index} className="flex-1">
            <Skeleton
              width="100%"
              height={256}
              className="md:h-72 lg:h-80 w-full md:w-[625px] lg:w-[625px] rounded-2xl"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export function SkeletonCardSectionDesign() {
  const isMobile = useWindowSize();
  return (
    <div className="container flex pb-11 md:mt-0 flex-col pl-5 -pr-5 md:px-5 mx-auto relative">
      <div className="flex gap-[10px] items-center justify-between">
        <div className="flex gap-[10px] items-center">
          {/* Skeleton for the GiftCardIcon and text */}
          <Skeleton width={40} height={40} />
          <Skeleton width={120} height={30} />
        </div>

        {/* Skeleton for the carousel navigation arrows */}
        <div className="flex gap-4">
          <Skeleton circle width={35} height={35} />
          <Skeleton circle width={35} height={35} />
        </div>
      </div>

      {/* Carousel skeleton structure */}
      <div className="pt-3.5 md:pt-7">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 h-[200px] overflow-hidden">
          {[...Array(6)].map((_, index) => (
            <div
              className="flex flex-col slider justify-center items-start"
              key={index}
            >
              {/* Skeleton for the card image */}
              <Skeleton width={isMobile ? 160 : 196} height={126} className="rounded-lg" />

              {/* Skeleton for the card title */}
              <Skeleton width={100} height={20} className="mt-2" />

              {/* Skeleton for the savings amount */}
              <Skeleton width={140} height={20} className="mt-2" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function SkeletonAccessExperienceCardSectionDesign() {
  const isMobile = useWindowSize();
  return (
    <div className="container flex pb-11 md:mt-0 flex-col pl-5 -pr-5 md:px-5 mx-auto relative">
      <div className="flex gap-[10px] items-center justify-between">
        <div className="flex gap-[10px] items-center">
          {/* Skeleton for the GiftCardIcon and text */}
          <Skeleton width={40} height={40} />
          <Skeleton width={120} height={30} />
        </div>

        {/* Skeleton for the carousel navigation arrows */}
        <div className="flex gap-4">
          <Skeleton circle width={35} height={35} />
          <Skeleton circle width={35} height={35} />
        </div>
      </div>

      {/* Carousel skeleton structure */}
      <div className="pt-3.5 md:pt-7">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 h-[220px] md:h-[260px] overflow-hidden">
          {[...Array(6)].map((_, index) => (
            <div
              className="flex flex-col justify-center items-start"
              key={index}
            >
              {/* Skeleton for the card image */}
              <Skeleton width={isMobile ? 160 : 196} height={isMobile ? 160 : 196} className="rounded-lg" />

              {/* Skeleton for the card title */}
              <Skeleton width={100} height={20} className="mt-2" />

              {/* Skeleton for the savings amount */}
              <Skeleton width={140} height={20} className="mt-2" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function SkeletonCustomerRelationData() {
  return (
    <div className="container px-5 mx-auto pb-11 md:py-0">
      <div className="py-4 px-5 md:px-6 md:mb-9 bg-[#FFEAEA] border border-[#f5437e] rounded-[14px] flex flex-col md:flex-row gap-4 md:gap-2 justify-between md:items-start xl:items-center">
        {/* Left Section with customer data */}
        <div className="text-[#FF0055] font-Inter justify-center flex flex-col xl:flex-row items-center gap-2 md:gap-1">
          <div className="flex gap-4 flex-row w-full md:w-auto justify-between md:justify-start xl:justify-center xl:items-center items-start">
            <div className="flex items-center relative">
              {/* Skeletons for image ellipses */}
              <Skeleton circle width={36} height={36} className="z-0" />
              <Skeleton circle width={36} height={36} className="z-10 -ml-2" />
              <Skeleton circle width={36} height={36} className="z-20 -ml-2" />
            </div>

            <div className="flex flex-col xl:flex-row xl:items-center md:justify-center gap-1">
              <div className="flex w-full md:w-auto gap-1">
                {/* Skeleton for smile icon and text */}
                <Skeleton width={20} height={20} />
                <Skeleton width={70} height={24} />
              </div>

              {/* Skeleton for additional text */}
              <Skeleton width={150} height={20} className="md:text-xs lg:text-sm" />

              <div className="flex w-full md:w-auto items-center gap-1">
                {/* Skeleton for rewards text */}
                <Skeleton width={70} height={24} />
                <Skeleton width={50} height={20} className="md:text-xs lg:text-sm" />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section for mapping through customer information */}
        <div className="flex flex-row justify-between md:justify-start gap-2 xl:gap-5 overflow-hidden">
          {[...Array(3)].map((_, index) => (
            <div
              className="flex flex-col xl:flex-row justify-center xl:items-center items-start gap-1 text-[#FF0055] font-Inter"
              key={index}
            >
              {/* Skeleton for cashback icons */}
              <Skeleton circle width={32} height={32} className="md:w-5 md:h-5" />
              <Skeleton width={50} height={24} />
              <Skeleton width={100} height={20} className="md:text-xs lg:text-sm overflow-hidden" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


export default function LandingPageSkeleton() {
  return (
    <>
      {SkeletonLandingBannerSection()}
      {SkeletonWhatsNewCard()}
      {SkeletonPopularCategory()}
      {SkeletonPopularCategoryBanner()}
      {SkeletonCardSectionDesign()}
      {SkeletonCardSectionDesign()}
      {SkeletonCardSectionDesign()}
      {SkeletonCustomerRelationData()}
      {SkeletonAccessExperienceCardSectionDesign()}
      {SkeletonAccessExperienceCardSectionDesign()}
      {SkeletonPopularRetailer()}
    </>
  )
}
import { cashbackIcon, discountIcon, experienceIcon, giftCardIcon, membershipIcon } from "../assets/svg/index.js";

export const tabMapping = {
  merchant_funded_offers: { title: "Discount Offers", Icon: discountIcon },
  affiliate_cashback: { title: "Cashback", Icon: cashbackIcon },
  gift_card: { title: "Gift Cards", Icon: giftCardIcon },
  access_n_membership: { title: "Access & Membership", Icon: membershipIcon },
  experiences: { title: "Experiences", Icon: experienceIcon },
};

export const categoryMapping = {
  Categories: "brand_categories",
  "Event & Occasion": "events",
  Retailers: "brands",
  "Spend Type": "offer_categories",
};

// constant for offer_type 
export const cardTitleToPath = (cardTitle) => {
  const pathMapping = {
    gift_card: "giftcard",
    affiliate_cashback: "cashback",
    merchant_funded_offers: "discount",
    access_n_membership: "membership",
    experiences: "experience",
  };
  return pathMapping[cardTitle] || "/";
};

export const getOfferType = (key) => {
  const OfferType = {
    merchant_funded_offers: "Discount Offers",
    affiliate_cashback: "Cashback",
    gift_card: "Gift Cards",
    access_n_membership: "Access & Membership",
    experiences: "Experiences"
  };
  return OfferType[key] || null;
};

export const getOfferTypeResultAPI = (key) => {
  const OfferType = {
    "discount": "merchant_funded_offers",
    "cashback": "affiliate_cashback",
    "giftcard": "gift_card",
    "membership": "access_n_membership",
    "experience": "experiences",
  };
  return OfferType[key] || null;
};

const IST_OFFSET = 330;
const DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_DATE_FORMAT = 'ddd, DD MMM';

export { IST_OFFSET, DATE_FORMAT, DISPLAY_DATE_FORMAT };

// Data structure for the different offer types
const OfferTypes = {
  gift_card: {
    title: "Gift Cards",
    Icon: giftCardIcon,
    key: "gift_card",
  },
  merchant_funded_offers: {
    title: "Discount Offers",
    Icon: discountIcon,
    key: "merchant_funded_offers",
  },
  affiliate_cashback: {
    title: "Cashback",
    Icon: cashbackIcon,
    key: "affiliate_cashback",
  },
  access_n_membership: {
    title: "Access & Membership",
    Icon: membershipIcon,
    key: "access_n_membership",
  },
  experiences: {
    title: "Experiences",
    Icon: experienceIcon,
    key: "experiences",
  },
};

// Function to get offer type based on the key
export const getOfferTypeResult = (key) => {
  // Return the object with title, icon, and key based on the key provided
  return OfferTypes[key] || null;
};

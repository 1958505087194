import useWindowSize from "../../../hooks/useWindowSize";

export default function FooterCard({ item }) {
	const isMobile = useWindowSize();

	return (
		<div className="flex gap-3 md:gap-0  items-start lg:justify-end">
			<div className="bg-white w-[190px] md:w-[210px] h-full lg:w-[245px] rounded-[10px] p-4 flex gap-2 items-start lg:items-center lg:justify-center">
				<img src={item?.logo} alt={item?.identifier} className="w-5 h-5" />

				<div className="font-Inter font-medium text-sm">
					{item.identifier === "PARTNERED_BRAND_NUMBER" && `${item?.data}  Brands ${isMobile ? "" : "Partnered"}`}
					{item.identifier === "TOTAL_MONEY_SAVED_NUMBER" && `${item?.data}  Saved`}
					{item.identifier === "INSTITUTION_SERVED_NUMBER" && `${item?.data}  Institutions ${isMobile ? "" : "Served"}`}
					{item.identifier === "CUSTOMER_SERVED_NUMBER" && `${item?.data}  Customers ${isMobile ? "" : "Served"}`}
				</div>
			</div>
		</div>
	);
};

import * as yup from 'yup';

export const createLoginValidationSchema = (otpValidate) => {
	return yup.object().shape({
		mobile_number: yup.string().required("Mobile Number is required"),
		...(otpValidate && {
			otp: yup.string().required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
		}),
	});
};

export const createSignUpValidationSchema = (otpValidate) => {
	return yup.object().shape({
		name: yup.string().required("Full Name is required"),
		mobile_number: yup.string().required("Mobile Number is required"),
		...(otpValidate && {
			otp: yup.string().required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
		}),
	})
};

export const createRewardDetailGiftCardValidationSchema = (min, max) => {
	return yup.object().shape({
		amount: yup
			.number()
			.typeError('Amount must be a number')
			.nullable()
			.transform((value, originalValue) => (originalValue === '' ? null : value))
			.required('Amount is required')
			.integer('Amount must be an integer')
			.min(min, `Amount must be at least ₹${min}`)
			.max(max, `Amount not be more than ₹${max}`),
	});
};

export const createRewardDetailGiftCardValidationSlabSchema = () => {
	return yup.object().shape({
		amount: yup
			.number()
			.integer('Amount must be an integer')
			.required('Amount is required')
	});
};

export const createRewardDetailGiftCardPaymentDetailValidationSchema = () => {
	return yup.object().shape({
		name: yup.string().required("Full Name is required"),
		email: yup
			.string()
			.required("Email is required")
			.email("Please enter a valid email address")
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				"Please enter a valid email address"
			)
			.max(255, "Email must not exceed 255 characters"),
		mobile_number: yup.string().required("Mobile Number is required").matches(/^\d{10}$/, "Moblie Number must be exactly 10 digits"),
	})
};

export const createUserProfileEmailValidationSchema = () => {
	return yup.object().shape({
		email: yup
			.string()
			.required("Email is required")
			.email("Please enter a valid email address")
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				"Please enter a valid email address"
			)
			.max(255, "Email must not exceed 255 characters"),
	});
};

export const createUserProfileNameSchema = () => {
	return yup.object().shape({
		name: yup.string().required("Full Name is required"),
	});
};

export const createContactUsFormValidationSchema = () => {
	return yup.object().shape({
		name: yup.string().required("Full Name is required"),
		mobile_number: yup.string().required("Mobile Number is required").matches(/^\d{10}$/, "Moblie Number must be exactly 10 digits"),
		email: yup.string().required("Email is required").email("Please enter a valid email address").matches(
			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
			"Please enter a valid email address"
		),
		message: yup.string().required("Message is required"),
		subject: yup.string().required("Subject is required"),
	});
};

export const createDiscountOfferPublicCodeForm = () => {
	return yup.object().shape({
		name: yup.string().required('Full Name is required'),
		companyName: yup.string().required('Company Name is required'),
		email: yup.string().email('Invalid email format').required('Email ID is required'),
	});
}
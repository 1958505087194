import { ENDPOINTS } from "../constants";
import { GET } from "../method";

export const bannerData = async () => {
    const url = ENDPOINTS.BANNER_URL
    return GET(url);
}

export const whatsNewData = async (id) => {
    const url = ENDPOINTS.WHATSNEW_URL(id)
    return GET(url);
}

export const popularCategoriesData = async (id) => {
    const url = ENDPOINTS.POPULAR_CATEGORIES_URL(id)
    return GET(url);
}

export const footerStatData = async () => {
    const url = ENDPOINTS.FOOTER_STAT_URL
    return GET(url);
}

export const publicRewardsData = async (id) => {
    const url = ENDPOINTS.PUBLIC_REWARDS_URL(id)
    return GET(url);
}

export const getGlobalSearch = async (id) => {
    const url = ENDPOINTS.GLOBAL_SEARCH(id);
    return GET(url);
}
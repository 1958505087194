import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { search } from "../../../assets/svg";

import { getGlobalSearch } from "../../../Services/APIs/LandingPage";

export default function SearchBar({ searchTerm, SetSearchTerm, setSearchResult, setSearchLoading, setShow }) {
  const handleInputChange = (event) => {
    setShow(true);
    SetSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!searchTerm || searchTerm?.length < 3) return; // Avoid empty search terms or length minimum 
      setSearchLoading(true);
      try {
        const param = `query=${searchTerm}`;
        const result = await getGlobalSearch(param);
        if (result?.status === 200) {
          setShow(true);
          setSearchResult(result?.data);
        }
      } catch (e) {
        console.log(e?.data?.error);
      } finally {
        setSearchLoading(false);
      }
    };

    // Debouncing: wait for 3 seconds after the user stops typing
    const timer = setTimeout(() => {
      fetchData();
    }, 300);

    // Clean up the timeout when searchTerm changes or component unmounts
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div className="container mx-auto">
      <div className="border flex gap-4 items-center bg-[#F7F7F7] border-[#E5E5E5] rounded-[10px] md:w-[280px] lg:w-[420px] h-11 px-4 mx-auto">
        <img src={search} alt="search" loading="lazy" />

        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="search for a category or reward"
          className="placeholder-[#3B3E63] bg-[#F7F7F7] font-Inter placeholder:font-Inter text-sm border-0 p-0 w-full h-10 focus:border-transparent focus:outline-none focus:ring-0 placeholder:text-sm appearance-none"
        />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  SetSearchTerm: PropTypes.func.isRequired,
};
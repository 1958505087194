import axios from "axios";
import { BACKEND_URL } from "./constants";

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    ...(process.env.NODE_ENV !== 'production' && { application: "BANKCO" }),
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiRequest = async (method, url, data = null, config = {}) => {
  try {
    const response = await axiosInstance({ method, url, data, ...config, });

    return response;
  } catch (error) {
    console.error(`Error with ${method.toUpperCase()} request to ${url}:`, error);

    throw error.response ? error?.response : new Error("Network Error");
  }
};

export const GET = (url, config = {}) => apiRequest("get", url, null, config);

export const POST = (url, data, config = {}) => apiRequest("post", url, data, config);

export const PUT = (url, data, config = {}) => apiRequest("put", url, data, config);

export const PATCH = (url, data, config = {}) => apiRequest("patch", url, data, config);

export const DELETE = (url, data, config = {}) => apiRequest("delete", url, data, config);

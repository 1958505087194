import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowSize from '../../../hooks/useWindowSize';

export default function RewardDetailSkeleton() {
  const isMobile = useWindowSize();
  return (
    <div className="container mx-auto px-5">
      <Skeleton height={isMobile ? 150 : 200} className="my-3.5 md:my-6 rounded-[20px]" />
      <Skeleton width={200} className="my-4" />
      <Skeleton height={40} width={300} className="mb-4" />

      <div className="w-full flex flex-col lg:flex-row gap-10">
        <div className="w-full lg:w-1/2 xl:w-full">
          <div className="w-full flex md:flex-row flex-col gap-8">
            <div className="xl:w-1/2 w-full flex flex-col gap-2">
              <Skeleton width={isMobile ? 300 : 380} height={260} className="mb-2" />
              <Skeleton height={30} width={200} />
              <Skeleton count={5} />
              <div className=" flex gap-5">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <div className=" flex gap-5">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <div className=" flex gap-5">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
            </div>

            <div className="hidden xl:flex flex-col gap-2 xl:w-1/2">
              <Skeleton height={40} width={200} />
              <Skeleton height={30} />
              <Skeleton count={5} />
              <div className=" flex gap-5 my-2">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <div className=" flex gap-5 my-2">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <div className=" flex gap-5 my-2">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <div className=" flex gap-5 my-2">
                <Skeleton height={30} width={30} />
                <Skeleton height={30} width={200} />
              </div>
              <Skeleton height={30} width={200} />
              <Skeleton count={4} />
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2">
          <Skeleton height={400} className="mb-4" />
        </div>
      </div>

      <Skeleton className="my-2" />
      <Skeleton count={3} className="my-2" />
      <Skeleton height={150} className="my-3.5 md:my-6" />
    </div>
  )
}
import React from 'react';
import { Link } from 'react-router-dom';

export default function FooterLogo({ logo, link }) {
	return (
		<Link to={link} target="_blank" rel="noopener noreferrer">
			<div className="w-12 h-12 cursor-pointer rounded-full border flex justify-center items-center border-[#3B3E63]">
				{logo}
			</div>
		</Link>
	);
};
import React from "react";

export default function Spinner({ width, height, color = "#fa86ac" }) {
	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
			<div
				className="spinner-border"
				role="status"
				style={{ color: color, width: width, height: height, }}
			></div>
		</div>
	);
};

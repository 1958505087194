import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function OfferSummaryPageSkeleton() {
  return (
    <div className="relative font-Inter">
      <div className="container px-5 mx-auto">
        <Skeleton height={150} className="my-4 md:my-6 md:h-52 w-full rounded-[20px]" />
      </div>

      <div className="container mx-auto px-5 flex gap-1 my-4">
        <Skeleton width={300} height={36} />
      </div>

      <div className="container mx-auto px-5 pb-6 font-semibold text-4xl">
        <Skeleton width={200} height={36} />
      </div>

      <div className="container mx-auto px-5 flex md:flex-row flex-col justify-between">
        <div className="flex gap-10">
          <Skeleton width={10} height={400} />
          <div className="flex flex-col gap-5 relative">
            <div className="flex md:flex-row flex-col gap-2">
              <Skeleton width={196} height={126} />
              <div className="flex flex-col gap-4">
                <Skeleton width={150} height={24} />
                <Skeleton count={3} width={200} />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Skeleton width={150} height={24} />
              <Skeleton count={3} width={200} />
            </div>
            <div className="flex flex-col gap-2">
              <Skeleton width={150} height={24} />
              <Skeleton count={3} width={200} />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div className='my-2'>
            <div className="container max-w-[410px] md:w-[320px] lg:w-[410px] h-auto py-8 border border-[#BDBDBD] font-Inter bg-white rounded-[20px] flex flex-col gap-4 mb-8 justify-center px-6 md:px-7">
              <Skeleton width={150} height={24} />
              <div className="py-4 border-b border-[#BDBDBD] flex flex-col gap-4 md:gap-[22px]">
                <Skeleton width={200} height={20} />
                <Skeleton width={180} height={20} />
                <Skeleton width={190} height={20} />
              </div>
              <Skeleton width={250} height={24} />
              <Skeleton width={200} height={20} />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-8 mx-auto px-5">
        <Skeleton width={300} height={20} className="md:ml-12 pt-7" />
        <div className="flex md:flex-row flex-col justify-between w-1/2 pt-4 md:ml-12">
          <Skeleton width={100} height={20} />
          <Skeleton width={120} height={20} />
          <Skeleton width={140} height={20} />
        </div>
      </div>
    </div>
  );
};

import { POST } from "../method.js";
import { ENDPOINTS } from "../constants.js"

export const sendOTP = async (mobileNumber, countryCode) => {
	const url = ENDPOINTS.OTP_VALIDATION

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
	};

	return POST(url, data);
};

export const resendOTP = async (mobileNumber, countryCode) => {
	const url = ENDPOINTS.RESEND_OTP

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
		type: "text"
	};

	return POST(url, data);
};

export const loginAPI = async (mobileNumber, countryCode, otp) => {
	const url = ENDPOINTS.LOGIN;

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
		otp: otp,
	};

	return POST(url, data);
};

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiEdit } from "react-icons/fi";
import { IoCloseCircle } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

import Spinner from "../CommonComponents/Spinner/index.js";

import { check } from '../../assets/svg/index.js';

import { useAuth } from "../../hoc/AuthContext.js";

import useWindowToTop from "../../hooks/useWindowScrollToTop.js";

import { loginAPI, resendOTP, sendOTP } from "../../Services/APIs/Login.js";
import { userDetail } from "../../Services/APIs/RewardListing.js";

import { cardTitleToPath } from "../../Utils/Constant.js";
import { createLoginValidationSchema } from "../../Utils/Validation.js";

import { POLICY_PAGE, POLICY_PRIVACY_PAGE, TERMS_PAGE } from "../../Routes/RoutesContant.js";

export default function Login({ setShowSignUp }) {
  const { login, setShowLogin, cardInfo, setSubVarientIdGlobal, setSelectedCardData, setWindowLocationUrl,
    windowLocationUrl } = useAuth();
  const navigate = useNavigate();

  const actualUrl = window?.location?.origin;

  const [otpValidate, setOtpValidate] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [resendNumber, setResendNumber] = useState(false);

  useEffect(() => {
    let interval;

    if (resendTimer > 0 && otpSent) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendTimer, otpSent]);

  const scrollToTop = useWindowToTop(0, 1000); // Scroll to top with 1-second duration

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(createLoginValidationSchema(otpValidate)),
  });

  const handleVerifyOTP = async (data, resend = false) => {
    if (data?.mobile_number?.length !== 10) return setError("Mobile Number must be exactly 10 digits");
    setLoading(true);

    try {
      const response = await sendOTP(data.mobile_number, countryCode);

      if (response.status === 200) {
        setOtpValidate(true);
        setResendNumber(true);
        setMessage(response?.data?.message);
        setError("");

        if (resend) {
          setOtpSent(true);
          setResendTimer(90);
        }
      }
    } catch (e) {
      setError(e ? e?.data?.error : e?.data?.mobile_number[0]);
    } finally {
      setLoading(false);
    }
  };

  // resend OTP function
  const handleResendOTP = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    if (phoneNumber?.length !== 10) return setError("Mobile Number must be exactly 10 digits");
    setLoading(true);
    try {
      const response = await resendOTP(phoneNumber, countryCode);

      if (response.status === 200) {
        setOtpValidate(true);
        setResendNumber(true);
        setMessage(response?.data?.message);
        setError("");
        setOtpSent(true);
        setResendTimer(90);
      }
    } catch (e) {
      setError(e ? e?.data?.error : e?.data?.mobile_number[0]);
    } finally {
      setLoading(false);
    }
  }

  // user detail for subvarient ID
  const handleUserDetails = async () => {
    try {
      const result = await userDetail();

      if (result.status === 200) {
        const defaultCard = result.data.cards.find((item) => item.is_default);

        if (defaultCard) {
          localStorage.setItem('subId', defaultCard.sub_variant.id);
          setSubVarientIdGlobal(defaultCard.sub_variant.id);
          localStorage.setItem('selectedCard', JSON.stringify(defaultCard));
          setSelectedCardData(defaultCard);

          const path = cardTitleToPath(cardInfo?.offer_type);
          navigate(`/rewards/${path}/${cardInfo?.id}/${cardInfo?.slug}`);
        } else {
          navigate("/rewards");
        }
      } else {
        navigate("/rewards");
      }
    } catch (error) {
      navigate("/rewards");
    } finally {
      setLoading(false);
      setShowLogin(false);
    }
  };

  const handleVerifyLogin = async (data) => {
    setLoading(true);

    try {
      const response = await loginAPI(data.mobile_number, countryCode, data.otp);

      if (response.status === 200) {
        const token = response?.data?.token;

        login(token);
        setMessage(response?.data?.message);

        if (windowLocationUrl) {
          await handleUserDetails();
          const url = new URL(windowLocationUrl);
          const path = url.pathname + url.search;
          if (windowLocationUrl === actualUrl) {
            navigate(`/rewards`);
          } else {
            navigate(path);
          }
          setWindowLocationUrl("");
        } else if (cardInfo?.offer_type) {
          await handleUserDetails();
        } else if (cardInfo?.popularCategory) {
          navigate(`/rewards/?filters={"brand_categories":[${cardInfo?.id}]}`);
        } else {
          await handleUserDetails();
          navigate(`/rewards`);
        }

        setLoading(false);
        setShowLogin(false);
      }
    } catch (error) {
      setError(error?.data?.mobile_number?.[0] || error?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    if (!otpValidate || !resendNumber) {
      handleVerifyOTP(data);
    } else {
      handleVerifyLogin(data);
    }
  };

  const handleChangeNumber = () => {
    setResendNumber(false);
    setOtpValidate(false);
    setMessage("");
    setError("");
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
    if (e.key === 'Delete') {
      setPhoneNumber('');
    }
  };

  function renderPhoneInput() {
    return (
      <div className="w-full pt-0 md:pt-6">
        <Controller
          name="mobile_number"
          control={control}
          render={({ field }) => (
            <div className="relative z-0">
              <PhoneInput
                country={"in"}
                inputClass="font-Inter"
                inputMode="numeric"
                countryCodeEditable={false}
                onChange={(value, country) => {
                  const phoneWithoutCountryCode = value.startsWith(country.dialCode)
                    ? value.substring(country.dialCode.length).trim()
                    : value.trim();
                  field.onChange(phoneWithoutCountryCode);
                  setCountryCode(country.dialCode);
                  setPhoneNumber(phoneWithoutCountryCode);
                }}
                placeholder="Mobile Number"
                disableDropdown={otpValidate}
                disabled={resendNumber}
              />

              {resendNumber && (
                <div
                  className="z-10 font-medium text-sm underline py-3 absolute top-2 right-4 font-Inter-Tight cursor-pointer"
                  onClick={handleChangeNumber}
                >
                  <FiEdit size={18} />
                </div>
              )}
            </div>
          )}
        />

        {message && message.length > 0 && !error && (
          <div className="flex mt-2 gap-2 text-sm text-[#09CB11] font-Inter font-medium">
            <img src={check} alt="check" />
            {message}
          </div>
        )}

        {error && (
          <div className="flex items-center mt-2 gap-2 text-sm text-red-600 font-Inter font-medium">
            <IoCloseCircle color="#e52424" size={18} />
            {error}
          </div>
        )}
      </div>
    );
  }

  function renderOtpInput() {
    return (
      <div className="w-full pt-6 relative">
        <input
          className="w-full otpInput rounded-[10px] font-Inter h-[60px] border border-[#E5E5E5] focus:outline-none py-3 px-6 bg-[#F7F7F7] text-[#1e1e1e] text-lg font-medium placeholder-shown:font-normal placeholder-[#6A6A6A]"
          id="otp"
          type="text"
          placeholder="Enter OTP"
          pattern="[0-9]{6}"
          minLength={6}
          maxLength={6}
          inputMode="numeric"
          {...register("otp")}
        />

        <span className="redStar text-[#FF0055]" style={{ display: otp ? 'none' : 'block' }}>*</span>

        {errors.otp && (
          <p className="py-2 px-4 text-red-600 font-Inter text-xs">
            {errors.otp.message}
          </p>
        )}

        <div className="py-3 absolute top-[30px] right-4 font-Inter-Tight">
          {resendTimer > 0 && otpSent ? (
            <button className="font-medium text-sm" disabled>
              Resend OTP in: {Math.floor(resendTimer / 60)}:
              {("0" + (resendTimer % 60)).slice(-2)}
            </button>
          ) : (
            <button className="font-medium text-sm underline" onClick={(e) => handleResendOTP(e)}>
              Resend OTP
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto h-full flex justify-center items-center flex-col">
      <div className="py-6 container md:w-full border border-[#BDBDBD] bg-white rounded-[20px] flex flex-col gap-4 mx-auto justify-center px-5 md:px-6">
        <div className="text-2xl font-semibold font-Inter">Sign In</div>

        <div className="text-lg font-normal font-Inter">
          Please enter the following details
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)} onKeyDown={handleEnterKey}
          onChange={e => { if (e.target.id === 'otp') setOtp(e.target.value) }}
        >
          {renderPhoneInput()}

          {otpValidate && renderOtpInput()}

          <div className="py-5 text-sm font-Inter-Tight">
            By continuing I agree to the{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setShowLogin(false);
                navigate(`${POLICY_PAGE}/${TERMS_PAGE}`)
              }}
            >terms & conditions</span>{" "}
            and{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setShowLogin(false);
                navigate(`${POLICY_PAGE}/${POLICY_PRIVACY_PAGE}`)
              }}
            >privacy policy</span>
          </div>

          <button
            className={`w-full font-Inter h-15 border py-4 border-[#E11F60] bg-[#F5437E] rounded-[10px] text-xl font-semibold text-white ${loading && "bg-[#f78baf] border-[#f78baf]"}`}
            disabled={loading}
          >
            {loading ? <Spinner color="#fff" /> : "Validate"}
          </button>
        </form>

        <div className="text-center font-Inter font-semibold text-lg">
          New to Banco?{" "}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              setShowSignUp(true);
              setShowLogin(false);
            }}
          >
            Sign up for free
          </span>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  setShowSignUp: PropTypes.func.isRequired,
};
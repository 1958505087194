import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import { bankco } from "../../../assets/svg";

import { FooterLogoData } from "../../../Utils/Data/FooterData";

import { footerStatData } from "../../../Services/APIs/LandingPage";

import FooterCard from "./FooterCard";
import FooterLogo from "./FooterLogo";

import { CONTACT_US_PAGE, POLICY_PAGE, POLICY_PRIVACY_PAGE, REFUND_PAGE, TERMS_PAGE } from "../../../Routes/RoutesContant";

export default function Footer() {
  const [footerData, setFooterData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchFooterStatData();
  }, []);

  const fetchFooterStatData = async () => {
    try {
      const result = await footerStatData();

      if (result.status === 200) {
        setFooterData(result?.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const address = "315/274, West End Marg, Saket Near Saket Metro Station New Delhi - 110030";
  function searchGoogle() {
    const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(address)}`;
    window.open(googleSearchUrl, '_blank');
  }

  return (
    <>
      <div className="flex flex-col bg-[#F3F4F6]">
        <div className=" flex gap-4 lg:gap-0 flex-col-reverse md:flex-row md:justify-between md:items-center container px-5 mx-auto">
          <div className="flex md:justify-between">
            <img src={bankco} alt="Bankco Logo" loading="lazy" />
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-0 container my-5 md:my-7 mx-auto">
            {footerData && footerData.map((item, index) => {
              return (
                <FooterCard item={item} key={index} />
              );
            })}
          </div>
        </div>

        <div className="md:border-t-[0.5px] border-[#7C7C7C]">
          <div className="container gap-2 px-5 mx-auto flex py-6 md:py-9 flex-col md:flex-row justify-start md:justify-between items-start font-Inter text-[16px]">
            <div className="md:w-[30%] w-full md:pr-7 leading-8">
              Adneto is India's largest digital ad network that uses advanced
              tech to gain insights into customer's spending behaviour and
              predicts their future spending patterns
            </div>

            <div className="flex flex-col mt-4 md:mt-0 text-sm md:text-base gap-4 underline">
              <div className="cursor-pointer">About Us</div>
              <div className="cursor-pointer" onClick={() => navigate(CONTACT_US_PAGE)} >Contact Us - Support</div>
              {/* <div className="cursor-pointer" onClick={() => navigate(CONTACT_US_PAGE)} >Contact Us - Support</div> */}
            </div>

            <div className="flex flex-col my-4 md:my-0 text-sm md:text-base gap-4 underline">
              <div className="cursor-pointer" onClick={() => navigate(`${POLICY_PAGE}/${POLICY_PRIVACY_PAGE}`)}>Privacy Policy</div>
              <div className="cursor-pointer" onClick={() => navigate(`${POLICY_PAGE}/${TERMS_PAGE}`)}>Terms of Use</div>
              <div className="cursor-pointer" onClick={() => navigate(`${POLICY_PAGE}/${REFUND_PAGE}`)}>Refund Policy</div>
            </div>

            <div className="flex flex-col mb-7 md:mb-0 gap-4 text-sm md:text-base">
              <div className="cursor-pointer underline"><a href="mailto:info@adneto.in">info@adneto.in</a></div>
              <div className="max-w-96 cursor-pointer" onClick={() => searchGoogle()} >Address:- <span className="underline">{address}</span></div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white flex flex-col md:flex-row gap-4 justify-between items-center py-5 container px-5 mx-auto">
        <div className="text-center font-Inter text-[16px]">
          © 2024 Adneto Technology Pvt. Ltd. All Rights Reserved.
        </div>

        <div className="flex gap-3">
          {FooterLogoData && FooterLogoData.map((item, index) => {
            return (
              <FooterLogo logo={item?.logo} link={item?.link} key={index} />
            );
          })}
        </div>
      </div>
    </>
  );
};

FooterCard.propTypes = {
  item: PropTypes.object.isRequired,
};

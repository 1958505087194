import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../hoc/AuthContext";

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const isPublic = searchParams.get("isPublic");

  // If isPublic is "true", treat it as a public route
  // if (isPublic === "true") {
  //   return <Outlet />;
  // }

  // For private pages, check if user is authenticated
  return isAuthenticated ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default PrivateRoute;

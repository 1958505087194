import React from "react";
import { Link, useRouteError } from "react-router-dom";
import PropTypes from "prop-types";

import { bankco } from "../../../assets/svg";

export default function Error() {
  const err = useRouteError();

  return (
    <div className="w-full h-screen flex-col flex justify-center items-center">
      <div className="flex mt-12 flex-col justify-center items-center h-full text-[#ED1C24] text-lg">
        <img src={bankco} alt="Loading..." />

        <div>Something went wrong!!!!!</div>

        <Link to="/" className="text-2xl font-Inter font-semibold cursor-pointer">Go to Home</Link>

        <div className="err">{err?.data}</div>
      </div>
    </div>
  );
};

Error.propTypes = {
  err: PropTypes.shape({
    data: PropTypes.string,
  }),
};
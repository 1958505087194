import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function skeletonContactUsTopBanner() {
  return (
    <div>
      <Skeleton className="my-9 md:my-6 z-0 w-full h-[100px] md:h-[200px] rounded-2xl md:rounded-[20px]" />
    </div>
  )
}

function skeletonheadAndBreadCrum() {
  return (
    <div className="flex flex-col gap-5">
      <Skeleton width={200} height={30} />
      <Skeleton width={300} height={40} />
    </div>
  )
}

function skeletonContactInfo() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 items-start">
        <Skeleton width={30} height={30} className='rounded-sm' />
        <Skeleton height={30} width={200} />
      </div>
      <div className="flex gap-2 items-start">
        <Skeleton width={30} height={30} className='rounded-sm' />
        <Skeleton count={3} height={30} width={200} />
      </div>
      <div className="flex gap-2 items-start">
        <Skeleton width={30} height={30} className='rounded-sm' />
        <Skeleton height={30} width={200} />
      </div>
    </div>
  )
}

function skeletonContactUSForm() {
  return (
    <div className="border border-[#BDBDBD] mb-10 rounded-[20px] px-4 md:px-9 pb-11 pt-12 flex flex-col gap-4">
      <Skeleton width={200} height={30} />
      <Skeleton count={3} width={300} height={20} />
      <Skeleton width="100%" height={60} />
      <Skeleton width="100%" height={60} />
      <Skeleton width="100%" height={60} />
      <Skeleton width="100%" height={60} />
      <Skeleton width="100%" height={60} />
      <Skeleton width="100%" height={60} className='rounded-[10px] border-[#E11F60] bg-[#F5437E]' />
    </div>
  )
}

export default function ContactUsPageSkeleton() {
  return (
    <div className='container px-5 mx-auto'>
      {skeletonContactUsTopBanner()}
      {skeletonheadAndBreadCrum()}
      <div className="flex md:flex-row flex-col gap-8">
        <div className="flex flex-col w-full md:w-1/2">
          <Skeleton width={200} height={30} className='mt-4' />
          <div className="border-b border-[#BDBDBD] py-4">
            {skeletonContactInfo()}
          </div>
          <div className="border-b-0 pt-4">
            {skeletonContactInfo()}
          </div>
        </div>

        <div className="w-full md:w-1/2">{skeletonContactUSForm()}</div>
      </div>
    </div>
  )
}
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowSize from '../../../hooks/useWindowSize';

export default function RewardListingSkeleton() {
  const isMobile = useWindowSize();

  const renderCardInfoSkeleton = () => (
    <div className="container px-5 mx-auto flex gap-4 overflow-x-auto pb-4">
      <div className="flex gap-4 overflow-hidden">
        <Skeleton width={40} height={40} className="min-w-[140px] md:min-w-[190px]  rounded-[10px]" />
        <Skeleton height={40} className="rounded-[10px] " />
        <Skeleton width={40} height={40} className="rounded-[10px]" />
        <Skeleton width={40} height={40} className="min-w-[140px] md:min-w-[190px]  rounded-[10px]" />
        <Skeleton height={40} className="rounded-[10px] " />
        <Skeleton width={40} height={40} className="rounded-[10px]" />
        <Skeleton width={40} height={40} className="min-w-[140px] md:min-w-[190px]  rounded-[10px]" />
        <Skeleton height={40} className="rounded-[10px] " />
      </div>
    </div>
  );

  const renderFilterTabsSkeleton = () => (
    <div className="hidden md:flex lg:flex-wrap md:gap-6 mb-2 items-center md:items-end justify-between md:justify-start">
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} height={40} className='md:w-[100px] lg:w-[120px]' />
      ))}
    </div>
  );

  const renderFilterCategorySkeleton = () => (
    <div className="left_Section w-[140px] xl:w-[196px] hidden md:flex flex-col font-Inter-Tight">
      <Skeleton width={100} height={30} className="mb-4" />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} width={140} height={20} className="mb-3" />
      ))}
      <Skeleton width={100} height={30} className="mb-4" />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} width={140} height={20} className="mb-3" />
      ))}
    </div>
  );

  const renderCardsSkeleton = () => (
    <div className="flex flex-wrap justify-start gap-[10px] md:gap-[30px]">
      {[...Array(12)].map((_, index) => (
        <div key={index} className="flex flex-col justify-start items-start">
          <Skeleton width={isMobile ? 140 : 195} height={126} className="rounded-md" />
          <Skeleton width={100} height={20} className="mt-2" />
          <Skeleton width={140} height={20} className="mt-1" />
        </div>
      ))}
    </div>
  );

  return (
    <div className="relative">
      <div className="container px-5 mx-auto">
        <Skeleton height={isMobile ? 100 : 256} className="my-4 md:my-6 w-full rounded-[20px]" />
      </div>

      {!isMobile && <div className="container px-5 mx-auto mb-4">
        <Skeleton width={300} height={20} />
      </div>
      }

      <div className="container px-5 mx-auto nameInfo my-7 flex gap-3 items-center">
        <Skeleton circle width={40} height={40} />
        <Skeleton width={200} height={30} />
      </div>

      {renderCardInfoSkeleton()}

      <div className="container px-5 mx-auto my-4 md:my-5">
        <Skeleton width={300} height={20} />
      </div>

      {isMobile && <div className="container px-5 mx-auto my-4 md:my-5">
        <Skeleton width={100} height={40} />
      </div>}



      <div className="md:my-10 md:gap-4 xl:gap-0 flex container px-5 mx-auto">
        {!isMobile && renderFilterCategorySkeleton()}

        <div className="right_Section w-full">
          {renderFilterTabsSkeleton()}
          {renderCardsSkeleton()}
        </div>
      </div>

      <div className="container px-5 mx-auto">
        <Skeleton height={192} className="hidden md:block mb-12 md:mb-28 rounded-[20px]" />
      </div>
    </div>
  );
};
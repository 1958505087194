import { GET, PATCH } from "../method";
import { ENDPOINTS } from "../constants.js"

export const addCardAPI = async (bin) => {
	const url = ENDPOINTS.ADD_CARD
	const data = { bin: bin };
	return PATCH(url, data);
};

export const userDetail = async () => {
	const url = ENDPOINTS.FULL_USER_DETAIL
	return GET(url);
}
export const userInfo = async () => {
	const url = ENDPOINTS.USER_DETAIL
	return GET(url);
}

export const offerCategoryList = async () => {
	const url = ENDPOINTS.OFFER_CATEGORY
	return GET(url);
}

export const offerEventList = async () => {
	const url = ENDPOINTS.EVENT_LIST
	return GET(url);
}

export const offerBrandList = async (id) => {
	const url = ENDPOINTS.BRAND_LIST(id)
	return GET(url);
}

export const offerBrandCategoryList = async () => {
	const url = ENDPOINTS.BRAND_CATEGORY_LIST
	return GET(url);
}

export const offerTabList = async () => {
	const url = ENDPOINTS.OFFER_TYPES_LIST
	return GET(url);
}

export const filterSearchResult = async (id) => {
	const url = ENDPOINTS.FILTER_SEARCH_RESULT(id)
	return GET(url);
}

export const rewardListingHeadBannerAPI = async (id) => {
	const url = ENDPOINTS.BANNER_DATA(id)
	return GET(url);
}

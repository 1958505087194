import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { dropDownArrow, upArrow } from '../../../assets/svg';

export default function Dropdown({ isOpenDrawer, setIsOpenDrawer, dropDownHeader, dropdownBody, isShowIcon }) {
	const dropdownRef = useRef(null);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleDropdown = () => {
		setIsOpenDrawer(!isOpenDrawer);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpenDrawer(false);
		}
	};

	return (
		<div ref={dropdownRef} className="relative inline-block text-left">
			<div className='flex justify-between gap-1.5' onClick={toggleDropdown}>
				{dropDownHeader()}

				{isShowIcon && (
					isOpenDrawer ? <img src={upArrow} alt={"UpArrow"} /> : <img src={dropDownArrow} alt={"dropDownArrow"} />
				)}
			</div>

			{isOpenDrawer && (
				<div
					className="origin-top-right absolute z-20 right-0 mt-4 rounded-[20px] w-max shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex="-1"
				>
					<div role="none">{dropdownBody()}</div>
				</div>
			)}
		</div>
	)
}

Dropdown.propTypes = {
	isOpenDrawer: PropTypes.bool.isRequired,
	setIsOpenDrawer: PropTypes.func.isRequired,
	dropDownHeader: PropTypes.func.isRequired,
	dropdownBody: PropTypes.func.isRequired,
	isShowIcon: PropTypes.bool
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { AuthProvider } from './hoc/AuthContext';

import reportWebVitals from './reportWebVitals';

import { routes } from './Routes';

import './scss/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <RouterProvider router={routes} />
  </AuthProvider>
);

reportWebVitals();
